/*
* Bootstrap overrides https://v4-alpha.getbootstrap.com/getting-started/options/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$blue: #023d7c !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e63 !default;
$red: #dc3545 !default;
$eggplant: #5c3943 !default;
$orange: #f58016 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$geraldine: #FB8886 !default;
$shadow:#1b1a1a26 !default;
$grey-teal: #51A095 !default;
$boulder: #7A7A7A !default;
$sea-shell: #F1F1F1 !default;
$light-slate-grey:#6B779A !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f1f1f1 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #2F3736 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$blue-chalk:#E3EBFF !default;
$regent-grey: #8692A6 !default;
$deep-sea-blue: #004C83 !default;
$geraldine: #FB8886;
$desert-storm: #F8F8F8;
$dawn: #A5A5A5;
$beluga: #F1F1F1;

$desert-storm: #F8F8F8 !default;
$light-black : rgba(0, 0, 0, 0.1) !default;
$light-grayish-blue: #f7fafc !default;
$light-cyan: #eaf0ef !default;
$lime-green: #65C08F !default;
$butter-scotch: #FFAD47;
$mercury: #E6E7E9;

$grays: () !default;
$grays: map-merge(("100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900,
    ),
    $grays);

$colors: () !default;
$colors: map-merge((
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "eggplant": $eggplant,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800,
        "shadow": $shadow,
        "grey-teal": $grey-teal,
        "boulder":$boulder,
        "sea-shell":$sea-shell,
        "blue-chalk": $blue-chalk,
        "dawn": $dawn,
        "regent-grey": $regent-grey,
        "deep-sea-blue":$deep-sea-blue,
        "geraldine": $geraldine,
        "light-slate-grey": $light-slate-grey,
        "black": $black,
        "desert-storm": $desert-storm,
        "beluga": $beluga,
        "light-black": $light-black,
        "light-cyan": $light-cyan,
        "light-grayish-blue": $light-grayish-blue,
        "lime-green": $lime-green,
        "butter-scotch": $butter-scotch,
        "mercury": $mercury),
    $colors);

$primary: $cyan !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $geraldine !default;
$danger: $geraldine !default;
$light: $white !default;
$dark: $gray-800 !default;
$greyTeal: $grey-teal !default;

$theme-colors: () !default;
$theme-colors: map-merge(("primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "greyTeal": $greyTeal,
        "beluga":$beluga,
    ),
    $theme-colors);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

$font-family-sans-serif: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
"Liberation Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

// Fonts

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-small-font-size: $small-font-size !default;
$blockquote-font-size: $font-size-base * 1.25 !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;