.table-width {
    width: 100%
}

.z-index-autocomplete {
    z-index: 0;
}
.table-row-border {
    border-top: .5px solid var(--bs-boulder) !important;
    border-top-width:.5px  !important;
    height: 50px;
}

.clickable{
    cursor: pointer;
}

.text{
font-size: 0.8rem;
}

.calender-style{
    border: 1px solid rgb(204, 204, 204);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 0.8rem;
    color: black;
    background-color: white;
}
.search-placeholder{
    padding-left: 19px;
    background-color: white;
    height: 2.4rem;
    font-size: 0.75rem !important;
}

.download-button{
    font-size: 1rem;
    display: flex; 
}  
.button-up {
    margin-bottom: 10px;
}

.download-text{
    @media only screen and (max-width: 1150px) and (min-width:768px) {
        display: none;
        }
}        

.custom-table{
    overflow-y: auto;
    max-height: 500px;
}
.table-header{
    height: 50px;
    position:sticky;
    top: 0;
    background-color:white,
}