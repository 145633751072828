.navbar {
  background-color: var(--bs-sea-shell);
}

.avfield-style {
  height: 45px;
}
.heading-center {
  justify-content: center;
  font-size: 20px;
  font-family: Poppins;
  font-style: bold;
  padding-top: 15px;
}
.form-style-left {
  padding-left: 35%;
  font-family: poppins;
  font-size: 14;
  padding-bottom: 25px;
}
.button-style-left {
  padding-left: 35%;
  padding-bottom: 25px;
  align-content: flex-end;
}
.form-style-right {
  padding-right: 30%;
  font-family: poppins;
  font-size: 14;
  padding-bottom: 25px;
}
.input-box {
  border-radius: 3%;
  border-color: var(--bs-boulder);
}
.button-back {
  border-radius: 2px;
  border-color: var(--bs-geraldine);
  color: var(--bs-geraldine);
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button-next {
  border-radius: 2px;
  background-color: var(--bs-geraldine);
  color: white;
  border-color: var(--bs-geraldine);
  padding-left: 30px;
  padding-right: 30px;
}
.button-align-left {
  padding-left: 40%;
}
.button-align-right {
  padding-right: 35%;
}
@media (max-width: 700.98px) {
  .style-row {
    display: block !important;
    width: 100% !important;
  }
}

.style-row {
  display: flex;
  width: 60%;
}
.form-style {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my-profile-text {
  font-size: 0.8rem;
  color: var(--bs-grey-teal) !important;
  padding-left: 3vw;
  font-weight: 500;
}
