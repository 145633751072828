#app-layout {
  font-family: Poppins;
}
.Toastify__toast--success {
  background: #69a39b !important;
}

.Toastify__toast--info {
  background: #FB8886 !important;
}
.Toastify__toast--warning {
  background: #FB8886 !important;
}

.Toastify__toast {
  border-radius: 10px !important;
}
