#payment_success {
  .background {
    background-color: var(--bs-grey-teal);
    margin-bottom: 0px;
    margin-top: 100px;
  }
  .background-whole-page {
    height: 100vh;
    background-color: var(--bs-grey-teal) !important;
    font-family: 'Poppins', 'normal' !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-align: center;
    font-size: 16px;
  }
  .payment-text-container {
    display: flex;
    justify-content: center;
  }
}
