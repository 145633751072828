#create-vendor-user {
    .confirm-button{
        background: var(--bs-geraldine);
        color: var(--bs-light);
        border-radius: 4px;
        border: 0px;
        width: 100%;
    }
}

.deactivate-user {
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
}

.deactivate-user-button {
    background: var(--bs-gray-500) !important;
    border-radius: 4px;
    border: 0px;
    width: 100%;
    border-color: var(--bs-gray-5000) !important;
}

th {
    font-size: smaller;
    line-height: 100%;
    font-weight: 600;
} 
td {
    font-size: small;
    line-height: 30px;
    color: #636363;
}

.user-multi-dropdown {
    .MuiChip-root {
        font-family: Poppins;
        font-size: .75rem;
        background-color: var(--bs-grey-teal);
        color: var(--bs-light);
        height: 25px;
    }

    .MuiChip-deleteIcon {
        color: unset;
        height: 20px;
        width: 20px;
    }

    .MuiChip-root {
        border-radius: 4px;
    }

    .MuiChip-label {
        font-size: 0.7rem;
    }
    .MuiSvgIcon-fontSizeSmall {
        height: 15px;
        width: 15px;
    }

    font-family: Poppins;
    font-size: medium;

    .label {
        font-family: Poppins;
        font-size: 14px;
    }

    .value {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: -0.3px;
        color: rgba(24, 24, 25, 0.42);
    }

    .input-label {
        font-family: Poppins;
        font-size: 14px;
        margin: 5px 0px 5px 0px;
    }

    .renew-label {
        font-family: Poppins;
        font-size: 15px;
        letter-spacing: -0.3px;
        color: var(--bs-gray-500);
    }

    .button-text {
        font-family: Poppins;
        font-style: normal;
        font-size: 16px;
        color: var(--bs-light);
    }

    .renew-button {
        width: 100%;
    }

    .subscription-card {
        box-shadow: 0px 35px 28px var(--bs-shadow);
        border-radius: 4px;
        padding: 30px;
        border-color: var(--bs-light);
    }
}
thead, tbody, tfoot, tr, td, th {
    border-width: 0 !important;
}

.select-wrapper{
    position: relative;
  }
  
.single-select-dropdown-icon{
    position: absolute;
    top: 10px;
    right: 25px;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    color: rgba(0, 0, 0, 0.54);
    width: 9px !important;
}
.designation-drop-down{
    .MuiChip-root {
        font-family: Poppins;
        background-color: var(--bs-grey-teal);
        color: var(--bs-light);
    }

    .MuiChip-deleteIcon {
        color: unset;
    }

    .MuiChip-root {
        border-radius: 4px;
    }

    .MuiChip-label {
        font-size: 0.7rem;
    }

    font-family: Poppins;
    font-size: medium;

    .label {
        font-family: Poppins;
        font-size: 14px;
    }

    .value {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: -0.3px;
        color: rgba(24, 24, 25, 0.42);
    }

    .input-label {
        font-family: Poppins;
        font-size: 14px;
        margin: 5px 0px 5px 0px;
    }
}
 
    
