.day-carousel {
  margin: 0 0;
  padding: 1.5rem 0;
}

input {
  font-size: 0.8rem !important;
}

.input-height {
  & .rc-time-picker-input {
    border: none;
    outline: none;
  }
  & .rc-time-picker-input:disabled {
    border: none !important ;
    outline: none !important ;
    color: black !important ;
    background: transparent !important;
    border: none !important ;
  }
  height: 45px;
}

.backgound-selected {
  background-color: var(--bs-sea-shell) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.rdrDefinedRangesWrapper {
  @media only screen and (max-width: 575px) {
    display: none;
  }
}

.rdrStaticRangeLabel {
  padding-left: 0.5rem !important;
}

label {
  font-size: 0.8rem;
}

.day-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 1rem;
  margin-right: 1rem;

  @media only screen and (max-width: 468px) {
    margin-right: 2rem;
  }

  border: solid var(--bs-gray-400);
  border-width: 0.2rem;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 2px 5px 2px var(--bs-gray-300);
  user-select: none;

  &.selected {
    background-color: var(--bs-info);
    color: var(--bs-light);
  }

  &.holiday {
    border: solid var(--bs-danger);
    color: var(--bs-danger);
  }

  .day {
    text-transform: capitalize;
  }

  .date {
  }
}

.slot-container {
  border: 1px dotted gray;
  padding: 1rem 5rem;

  @media only screen and (max-width: 768px) {
    padding: 1rem 1rem;
  }

  .slot-header {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;

    .title {
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--bs-info);
      margin-bottom: 0;

      &.holiday {
        color: var(--bs-danger);
      }
    }

    .reset a:hover {
      color: white;
    }
  }

  .slot-guide {
    font-size: 0.825rem;
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem 0;

    .box {
      margin-right: 0.5rem;

      &.available {
        background-color: var(--bs-light);
        width: 1rem;
        height: 1rem;
        border: 1px solid var(--bs-gray-400);
      }

      &.not-available {
        background-color: var(--bs-gray-400);
        width: 1rem;
        height: 1rem;
      }

      &.booked {
        background-color: var(--bs-info);
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0.5rem;

    .slot {
      padding: 0.5rem;
      border: 1px solid var(--bs-gray-400);
      border-radius: 5px;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &.not_available {
        background-color: var(--bs-gray-400);
      }

      &.booked {
        background-color: var(--bs-info);
        color: var(--bs-light);
      }

      &.selected {
        border-color: var(--bs-dark);
        box-shadow: 2px 2px 2px 1px var(--bs-gray-300);
      }
    }
  }

  .footer {
    display: flex;
    margin: 2rem 0;
    justify-content: center;

    a {
      margin-right: 0.5rem;
      min-width: 6rem;
    }
  }
}

.right-panel {
  border: 1px dotted gray;
  padding: 0;

  .form-group {
    border-bottom: 1px dotted gray;
    padding: 2rem 2rem;

    input {
      max-width: 10rem;
      margin-top: 0.5rem;
    }

    .selected-slots {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.5rem;

      .slot {
        font-size: 0.825rem;
        background-color: var(--bs-danger);
        color: var(--bs-light);
        padding: 0.3rem 0.5rem;
        border-radius: 4px;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        white-space: nowrap;

        .close {
          cursor: pointer;
          margin-left: 0.5rem;
        }
      }
    }

    .booking {
      margin-top: 0.5rem;
      border: 1px solid var(--bs-gray-400);
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      width: 10rem;

      a {
        color: var(--bs-light);
      }

      span {
        font-size: 1.5rem;
      }
    }
  }
}

//services page
.service-container {
  margin-top: 1rem;

  .card.service {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    border: 0.5px solid var(--bs-mercury);
    border-radius: 8px;
    filter: drop-shadow(4px 4px 21px rgba(0, 0, 0, 0.05));
    .image {
      width: 40%;

      img {
        width: 100%;
        height: 10rem;
        object-fit: cover;
        min-height: 10rem;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .details {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .label {
        position: absolute;
        right: 0;
        top: 0;
        background-color: var(--bs-danger);
        color: var(--bs-light);
        padding: 0.2rem 0.5rem;
        clip-path: polygon(1% 1%, 27% 100%, 100% 100%, 100% 1%);

        span {
          font-size: 0.825rem;
          text-transform: capitalize;
          padding-left: 1.5rem;
        }

        &.published {
          background-color: var(--bs-grey-teal);
        }
        &.publish-requested {
          background-color: var(--bs-geraldine);
        }
        &.draft {
          background-color: var(--bs-butter-scotch);
        }
      }

      .title {
        color: var(--bs-grey-teal);
        font-size: 1.1rem;
      }
    }
  }
}

//business info update page
#business-info-update {
  font-family: Poppins;
  font-size: medium;
  margin-bottom: 3rem;

  label {
    margin-bottom: 0.5rem;
  }

  .form-control {
    margin-bottom: 0.5rem;
  }

  .logo-container {
    position: relative;

    .logo-image {
      width: 100%;
      object-fit: cover;
      border: 1px solid #ced4da;
    }

    .close-btn {
      color: var(--bs-light);
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  .business-contact-info-text {
    color: var(--bs-grey-teal);
  }

  .file-drop-area {
    border: 1px solid #ced4da;
    border-radius: 3px;
    position: relative;
    width: 450px;
    max-width: 100%;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    align-items: center;
    display: flex;
  }

  .file-drop-area.is-active {
    background-color: #ced4da;
  }

  .fake-btn {
    background-color: var(--bs-geraldine);
    border: 1px solid var(--bs-geraldine);
    border-radius: 3px;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--bs-light);
    width: 30%;
    padding: 0.8rem;
    display: flex;
    justify-content: center;
    margin: 2px;
  }

  .file-msg {
    font-size: 0.6rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 70%;
    margin-left: 10px;
  }

  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .file-input:focus {
    outline: none;
  }
}

//subscription details
#subscription-details {
  font-family: Poppins;
  font-size: medium;

  .label {
    font-family: Poppins;
    font-size: 14px;
  }

  .value {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
  }

  .input-label {
    font-family: Poppins;
    font-size: 14px;
    margin: 5px 0px 5px 0px;
  }

  .renew-label {
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: #979797;
  }

  .button-text {
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    color: var(--bs-light);
  }

  .renew-button {
    width: 100%;
  }

  .field-style {
    height: 30px;
    width: 200px;
    padding: 5px !important;
    padding-left: 10px !important;
  }

  .update-button {
    width: 5%;
    height: 85%;
    color: var(--bs-geraldine);
    border-color: var(--bs-geraldine);
    background-color: white;
    padding: 5px !important;
    padding-left: 10px !important;
  }

  .subscription-card {
    box-shadow: 0px 35px 28px var(--bs-shadow);
    border-radius: 4px;
    padding: 25px;
    border-color: var(--bs-light);
  }
}

//service details
#service-details,
.facility-multi-dropdown {
  .MuiChip-root {
    font-family: Poppins;
    background-color: var(--bs-grey-teal);
    color: var(--bs-light);
  }

  .MuiChip-deleteIcon {
    color: unset;
  }

  .MuiChip-root {
    border-radius: 4px;
  }

  .MuiChip-label {
    font-size: 0.7rem;
  }

  font-family: Poppins;
  font-size: medium;

  .label {
    font-family: Poppins;
    font-size: 14px;
  }

  .value {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
  }

  .input-label {
    font-family: Poppins;
    font-size: 14px;
    margin: 5px 0px 5px 0px;
  }

  .renew-label {
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: #979797;
  }

  .button-text {
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    color: var(--bs-light);
  }

  .renew-button {
    width: 100%;
  }

  .subscription-card {
    box-shadow: 0px 35px 28px var(--bs-shadow);
    border-radius: 4px;
    padding: 30px;
    border-color: var(--bs-light);
  }
}

//service details
#service-details {
  .label {
    font-family: Poppins;
    font-size: 14px;
  }

  .value {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
  }

  .input-label {
    font-family: Poppins;
    font-size: 14px;
    margin: 5px 0px 5px 0px;
  }

  .renew-label {
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: #979797;
  }

  .button-text {
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    color: var(--bs-light);
  }

  .error-text {
    font-size: 13px;
  }
}

.duration-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #9a9a9a;
  text-align: center;
}

//schedule-details
#schedule-details {
  font-family: Poppins;
  font-size: medium;

  select {
    padding: 0.8rem;
    font-size: 0.8rem;
  }

  .label {
    font-family: Poppins;
    font-size: 0.8rem;
  }

  .header {
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 24px;
  }

  .day-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 24px;
  }

  .value {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
  }

  .input-label {
    font-family: Poppins;
    font-size: 14px;
    margin: 5px 0px 5px 0px;
  }

  .add-time-button {
    background: var(--bs-danger);
    border-radius: 5px;
    cursor: pointer;
  }

  .close-button {
    border-radius: 5px;
    cursor: pointer;
  }

  .day-button {
    border: 2px solid var(--bs-grey-teal);
    border-radius: 10px;
    justify-content: center;
    min-width: 100px;
    cursor: pointer;
    box-shadow: 4px 4px 22px rgba(0, 0, 0, 0.05);
    color: var(--bs-grey-teal);
    background-color: white;
  }

  .day-button-with-hour {
    border: 2px solid var(--bs-grey-teal);
    color: var(--bs-grey-teal);
  }

  .day-button-without-hour {
    border: 2px solid var(--bs-geraldine);
    color: var(--bs-geraldine);
  }

  .selected-day-card-with-hours {
    border: 2px solid var(--bs-grey-teal);
    color: var(--bs-light);
    background-color: var(--bs-grey-teal);
  }

  .selected-day-card-without-hour {
    border: 2px solid var(--bs-geraldine);
    color: var(--bs-light);
    background-color: var(--bs-geraldine);
  }

  .empty-day-card {
    opacity: 60%;
  }

  .add-shift-text {
    font-size: 0.7rem;
    font-weight: 600;
  }
}

//Service Preview
#service-preview {
  font-family: Poppins;
  font-size: medium;

  .sub-header-pharma {
    font-size: 18px;
    font-weight: 500;
    background-color: none;
  }

  .location-style {
    font-size: 11px;
    font-weight: 500;
    color: black;
    margin: 5px;
  }

  .card {
    border: 0;
  }

  .preview-card {
    background: var(--bs-light) !important;
    box-shadow: 5px 5px 21px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .card-head {
    background: var(--bs-boulder);
    border-radius: 4px 4px 0px 0px;
  }

  .card-head-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: center;
    color: var(--bs-light);
    padding: 15px 0 15px 0;
  }

  .business-card-hint {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #777777;
  }

  .dashed-line {
    border-top: 1px dashed var(--bs-light);
  }

  .slots-avail-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: var(--bs-grey-teal);
  }

  .business-card-head {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--bs-grey-teal);
    margin: 5px;
  }

  .doctor-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: var(--bs-dark);
    margin: 5px;
  }

  .label-danger {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: var(--bs-danger);
    margin: 5px;
  }

  .business-card-languages {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: #9e9e9e;
    margin: 5px;
  }

  .location {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: #737373;
    margin: 5px;
  }

  .amount {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--bs-grey-teal);
    margin: 0px;
  }

  .book-button {
    background: var(--bs-danger);
    border-radius: 5px;
    color: var(--bs-light);
  }

  .book-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--bs-light);
    margin: 5px;
  }

  p {
    font-size: 0.8rem;
  }

  .separation-line {
    width: 83rem;
  }
}

//bank details
#facility-detail {
  font-family: Poppins;
  font-size: medium;

  .drop-down-container {
    min-height: 60px;
    font-size: 0.8rem;
  }
}

#reschedule-detail {
  font-family: Poppins;
  background-color: var(--bs-desert-storm);

  .today-button {
    width: 100%;
    border: 1px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 0.8rem;
    color: black;
    background-color: white;
  }

  .today-button-active {
    border: 1px solid #fb8886 !important;
  }

  .day-button {
    background: #ffffff;
    box-shadow: rgba(6, 6, 8, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    border-width: 0;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    min-width: 150px;
  }

  .day-number {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .day-text {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .day-active {
    background-color: #51a095;
    color: white;
  }

  .top-content {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  .bottom-content {
    background-color: var(--bs-desert-storm);
  }

  .default-booking-slot-text {
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 24px;
    color: var(--bs-dark);
  }

  .default-slot {
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 24px;
    color: var(--bs-dark);
    font-weight: 600;
  }

  input {
    text-align: center;
    border: 1px solid #fffefe !important;
  }

  input[type='number'] {
    -webkit-appearance: textfield !important;
    -moz-appearance: textfield !important;
    appearance: textfield !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .slot-count {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #bbbbbb;
    box-sizing: border-box;
    background-color: white;
  }

  .slot-count .input-text.qty {
    width: 70px;
    height: 39px;
    text-align: center;
    background-color: transparent;
    border: 1px solid #efefef;
  }

  .slot-count.buttons_added {
    text-align: left;
    position: relative;
    white-space: nowrap;
    vertical-align: top;
  }

  .slot-count.buttons_added input {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    box-shadow: none;
  }

  .slot-count.buttons_added .minus,
  .slot-count.buttons_added .plus {
    padding: 7px 10px 8px;
    height: 41px;
    background-color: var(--bs-grey-teal);
    border: 1px solid #efefef;
    cursor: pointer;
    color: white;
    border-radius: 4px;
  }

  .slot-count.buttons_added .minus {
    border-right: 0;
  }

  .slot-count.buttons_added .plus {
    border-left: 0;
  }

  .slot-count.buttons_added .minus:hover,
  .slot-count.buttons_added .plus:hover {
    background: #51a095;
    opacity: 90%;
  }

  .slot-count input::-webkit-outer-spin-button,
  .slot-count input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  .slot-count.buttons_added .minus:focus,
  .slot-count.buttons_added .plus:focus {
    outline: none;
  }

  .label {
    font-size: 0.9rem;
  }

  .slot-status-box {
    display: flex;
    width: 16px;
    height: 16px;
  }

  .slot-selected-status {
    background-color: var(--bs-light) !important;
    border: 1px solid #fb8886;
    box-sizing: border-box;
  }

  .slot-unavailable-status {
    background-color: #fdcfcf !important;
    box-sizing: border-box;
  }

  .slot-booked-status {
    background-color: #c4e1db !important;
  }

  .nav {
    background-color: #e9e9ff;
    border-radius: 40px;
  }

  .nav a {
    color: var(--bs-dark);
    font-size: 0.8rem;
    cursor: pointer;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: var(--bs-light);
    border-color: #dee2e6;
    border-radius: 40px;
    cursor: pointer;
  }

  .nav-tabs .nav-link {
    border: 0;
  }

  .nav-item {
    border-radius: 40px;
  }

  .nav-tabs {
    padding: 0.1rem;
  }

  .card {
    border-radius: 0.55rem;
    height: 100%;
    cursor: pointer;
  }

  .card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .status-box-large {
    height: 23px;
    width: 50%;
  }

  .status-box-disabled {
    background-color: #c4e1db;
  }

  .today-text {
    margin-top: 0.1rem !important;
  }

  .date-picker {
    z-index: 10000;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .react-datepicker-popper {
    z-index: 10000;
  }

  .react-datepicker {
    font-family: Poppins;
    font-size: 0.7rem;
    background-color: var(--bs-light);
    color: #000;
    border: 1 px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker__navigation--previous {
    left: 2px;
  }

  .react-datepicker__navigation--next {
    right: 2 px;
  }

  .react-datepicker__month-container {
    float: left;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected
    .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3 rem;
    background-color: #51a095;
    color: var(--bs-light);
  }

  .react-datepicker__header {
    background-color: #fb8886;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: var(--bs-light);
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: var(--bs-light);
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #fb8886;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #fb8886;
  }
}

.multiple-input-custom {
  border: 1px #ced4da solid !important;
  height: 54px;
  background-color: white;
  border-radius: 5px !important;
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .Mui-focused {
    background-color: white;
    border: 1px solid #ced4da;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
 }
 .bottom-button-save-container {
  background-color: #f1f1f1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0.3rem;
}
.rc-time-picker-input[disabled] {
  color: #000 !important;
  background: #f7f7f7;
  cursor: not-allowed;
}

.tab-border {
  & .react-horizontal-scrolling-menu--wrapper {
    border: 1px solid #C1DEEF;
  }
  
}

.select-wrapper{
  position: relative;
}

.single-select-dropdown-icon{
position: absolute;
top: 0;
right: 10px;
bottom: 0;
justify-content: center;
align-items: center;
display: flex;
height: 100%;
color: rgba(0, 0, 0, 0.54);
width: 9px !important;
}

.text-mail-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}