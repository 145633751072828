@import 'bootstrap-variables';
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.view-routes {
  margin-top: 4rem;
  overflow-x: hidden;
}

.view-default-routes {
  overflow-x: hidden;
}

.nav {
  display: flex;
  justify-content: center;

  a {
    margin: 1rem;
    color: var(--bs-light);
  }
}

a:hover {
  text-decoration: none;
}

.empty-list-text {
  text-align: center;
  font-size: small;
}

.MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.custom-popover {
  .popover {
    width: 230px;
    max-width: 500px;
    box-shadow: 0px 0px 5px 2px var(--bs-shadow);
    border: none;
    border-radius: 2px;
    top: 15px !important;

    .popover-header {
      background-color: var(--white);
      border-bottom: 1px solid var(--bs-shadow);
      border-radius: 0px;
      font-family: 'Poppins';
      font-size: 14px;
      color: var(--white);
      padding: 15px 0px 15px 0px;
      text-align: center;
    }

    .popover-body {
      padding: 0px !important;
    }
  }
}
