.left-container {
  background-color: var(--bs-grey-teal);
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 25%;

  .logo-container {
    width: 65%;
    margin-bottom: -30px;
  }

  h2 {
    font-weight: 700;
    font-size: 1.75vw;
    margin-bottom: 50%;
  }

  h6 {
    font-size: 0.75vw;
    margin-bottom: calc(50% + 6vw);
    font-weight: 400;
    padding-left: 0.25vw;
  }

  img {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 767.98px) {
  .sm-h-20 {
    height: 25% !important;
  }

  .logo-container {
    margin-bottom: -45px !important;
  }

  .left-container {
    height: 100%;
    padding-bottom: 22.5%;

    h2 {
      font-size: 6vw;
      margin-bottom: 0;
    }
    h6 {
      font-size: 2.5vw;
      margin-bottom: -4.5vw;
    }

    img {
      width: 45%;
      right: -12.5%;
    }
  }
  .right-container {
    padding: 1rem !important;
    height: 80% !important;
    .invalid-feedback {
      margin-top: 0 !important;
      margin-bottom: -15.2px;
    }
    .form-group {
      margin-bottom: 1rem !important;
      input {
        padding: 0.25rem !important;
      }
      select {
        padding: 0.25rem !important;
        margin-bottom: 0.5rem !important;
      }
    }

    .services-container {
      margin-top: 0.5rem !important;
      h2 {
        font-size: 4.75vw !important;
        margin-bottom: 0 !important;
        font-weight: 600 !important;
      }
      hr {
        margin: 0.75rem 0 !important;
      }
      .card {
        width: 50% !important;
        padding: 0.75rem !important;
        min-height: 5rem !important;
        h5 {
          margin-bottom: 0 !important;
        }
        h6 {
          font-size: 3vw !important;
        }
        p {
          margin-bottom: 0.25rem;
        }
        svg {
          font-size: 1rem !important;
        }
        img {
          width: 10vw !important;
          height: 10vw !important;
          margin: 0.5rem 0 0.75rem 0 !important;
        }
      }
    }
    .submit-btn {
      width: 70% !important;
      margin-left: 15% !important;
      margin-right: 15% !important;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

select {
  padding: 0.8rem !important;
  font-size: 0.8rem !important;
}

.right-container {
  padding: 2rem 1.5rem;
  overflow: scroll;
  height: 100%;

  h2 {
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: var(--bs-grey-teal);
  }

  label {
    margin-bottom: 0.4rem;
    text-transform: capitalize;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .btn.submit {
    color: var(--bs-light);
    min-width: 25%;
  }

  hr {
    background-color: var(--bs-gray-600);
  }

  .svg-inline--fa {
    color: var(--bs-grey-teal);
  }

  .register-btn,
  .register-btn:focus {
    border-color: var(--bs-geraldine);
    background-color: var(--bs-geraldine);
    color: var(--bs-white);
    box-shadow: 0 0 0 0.25rem #fb8886;
  }

  input {
    width: 100%;
    padding: 0.8rem;

    @media (min-width: 992px) {
      width: 80%;
    }
  }

  select {
    padding: 0.8rem;
  }

  .steps-dark .step-item.active .step-count,
  .steps-dark .step-item.active .step-progress {
    background-color: var(--bs-grey-teal);
  }

  button.submit,
  .button.submit:focus {
    border-color: var(--bs-geraldine);
    color: var(--bs-white);
    box-shadow: 0 0 0 0.25rem var(--bs-geraldine);
    background-color: var(--bs-geraldine);
  }

  .button-text {
    font-size: 0.8rem;
  }
}

.services-container {
  margin-top: 2rem;

  .card {
    width: 100%;
    padding: 1.5rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 1rem 0;
    cursor: pointer;
    min-height: 10rem;

    &.active {
      border-color: var(--bs-danger);
    }

    p {
      margin-bottom: 0.5rem;
    }

    svg {
      font-size: 1.7rem;
    }

    img {
      width: 20%;
      border-radius: 50%;
      object-fit: cover;
      margin: 1rem 0;
    }

    &.summary {
      padding: 1.5rem;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      margin-bottom: 1rem;
      cursor: pointer;
      min-height: 4rem;

      :first-child {
        width: 75%;
      }

      :last-child {
        // width: 20%;
        text-align: right;
        font-size: 1rem;
      }

      &.active {
        border-color: var(--bs-danger);
      }

      h6 {
        margin-bottom: 0;
      }
    }

    @media (min-width: 992px) {
      width: 80%;
    }
  }

  .btn.add-more {
    width: 100%;
    background-color: var(--bs-gray-200);
    color: var(--bs-muted);
    border: 0;

    &:hover {
      background-color: var(--bs-gray-300);
    }
  }

  .summary-info {
    padding: 1rem;

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    .item-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    .renew_item {
      align-items: center;
      padding-left: 15%;
      padding-right: 15%;
      text-align: center;
    }
  }

  .btn.confirm {
    width: 70%;
    color: var(--bs-light);
    margin: 0.8rem;
  }

  .btn-renew-confirm {
    text-align: center;
  }
}

// service selection page

.summary-container {
  background-color: var(--bs-gray-200);
  margin: 0 1.5rem 0 -1.5rem;
  padding: 1.5rem;

  h2 {
    font-weight: 700;
  }

  .services {
    height: 70vh;

    .card {
      padding: 1.5rem;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      cursor: pointer;

      :first-child {
        width: 60%;
      }

      :last-child {
        width: 20%;
        text-align: right;
        font-size: 1rem;
      }

      &.active {
        border-color: var(--bs-danger);
      }

      h6 {
        margin-bottom: 0;
      }
    }
  }

  .footer {
    background-color: var(--bs-light);
    margin: auto -1.4rem -1.5rem -1rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 0.8rem;

    .entry {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      &.total {
        border-top: 1px solid var(--bs-gray-300);
        padding: 1rem 0;
      }
    }

    .btn {
      color: var(--bs-light);
    }
  }
}

.selection-container {
  padding: 1.5rem;

  h2 {
    font-weight: 700;
  }

  .services-container {
    margin-top: 2rem;

    .card {
      padding: 1.5rem;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 1rem 1rem 0;
      cursor: pointer;
      min-height: 20rem;

      &.active {
        border-color: var(--bs-danger);
      }

      p {
        margin-bottom: 0.5rem;
      }

      img {
        width: 20%;
        border-radius: 50%;
        object-fit: cover;
        margin: 1rem 0;
      }
    }
  }
}

.plan-modal {
  .plans {
    .card {
      padding: 0rem;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      cursor: pointer;

      &.active {
        border-color: var(--bs-danger);
      }
    }
  }

  .row {
    margin: 1.5rem 0;
  }
}

.subscription-modal {
  position: fixed;
  top: auto;
  right: 0;
  left: auto;
  bottom: 0;
  margin: 0;
}

.inter {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#service-subscription {
  font-family: Poppins;

  .added-services-container {
    height: 40vh;
    color: var(--bs-light);
    overflow: scroll;
  }

  .service-name {
    font-size: 0.9rem;
  }

  .plan-added-amount {
    font-size: 0.9rem;
  }

  .footer {
    .entry {
      display: flex;
      background-color: #f6f7fa;
      justify-content: space-between;

      &.total {
        border-top: 1px dashed var(--bs-gray-300);
      }
    }

    .btn {
      color: var(--bs-light);
      width: 100%;
    }
  }

  .rate-details {
    background-color: #f6f7fa;
  }

  .select-service-label {
    font-size: 1.5rem;
  }

  .left-content-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #51a095;
    height: 100vh;
    box-shadow: 0px 0px 1px 0px #888;
    z-index: 1;
  }

  .right-content-container {
    background-color: #f7f8fc;
    max-height: 100vh;
  }

  .service-list-col {
    overflow: scroll;
    height: 65vh;
  }

  .checkout-button-container {
    background-color: #f6f7fa;
  }

  .services-selected-text {
    opacity: 57%;
  }

  .selected-service-card {
    border-radius: 12px;
  }

  .vendor-registration-text {
    font-size: 1.5rem;
  }

  .header-bottom-text {
    color: rgba(255, 255, 255, 0.57);
    font-size: 0.7rem;
  }

  #service-card {
    .name {
      font-size: 1rem;
      line-height: 2rem;
    }

    .description {
      font-size: 0.8rem;
      color: #64676c;
      line-height: 2rem;
    }
  }
}

#plan-modal {
  font-family: Poppins;
  position: fixed;
  top: auto;
  right: 0;
  left: auto;
  bottom: 0;
  margin: 0;

  .card-body {
    flex: 1 1 auto;
    padding: 1.5rem 1rem;
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    max-height: calc(100vh - 310px);
    overflow-y: auto;
    padding: 0;
  }

  .select-subscription-plan {
    font-weight: bold;
    font-size: 1.7rem;
    color: #51a095;
  }

  .modal-header {
    border-bottom: 0px solid #dee2e6;
    border: 0px;
  }

  .modal-content {
    border-radius: 0.7rem 0.7rem 0rem 0rem;
    border-bottom: 0px solid #dee2e6;
    border: 0px;
  }

  .modal-backdrop.show {
    opacity: 0.1;
  }

  .package-text {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .label {
    font-size: 0.8rem;
  }

  .card {
    height: 100%;
  }

  .pharmacy-services-required-text {
    font-size: 0.8rem;
    cursor: pointer;
  }

  .additional-charge-text {
    font-size: 0.7rem;
  }

  .total-amount {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .checkout-rate-label {
    font-size: 0.8rem;
  }
}

.popover {
  z-index: 2080 !important;
  max-width: unset;
  box-shadow: #c1c2c2 0px 2px 8px;
}

td {
  padding: 0.8rem !important;
}

.table-bordered> :not(caption)>* {
  border-width: 0px 0;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  font-family: Poppins;
  border-color: inherit;
  border-style: solid;
  border-width: unset;
  text-align: center;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0px !important;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}