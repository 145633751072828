.drop-down-container {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 0.085rem;
  cursor: pointer;
}

.drop-down-header {
  min-height: 2rem;
  padding: 1rem;
  font-size: 17px;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  .icon {
    display: flex;
  }

  &:hover,
  &.active {
    background-color: rgba(255, 255, 255, 0.32);
  }
}

.drop-down-header .drop-down-body {
  position: relative;
  top: 10px;
  left: 20px;
}

.drop-down-body a {
  display: flex;
  text-decoration: none;
  padding: 2px 0px;
  font-size: 0.9rem;
  color: var(--bs-gray-400);
  font-weight: 400;
  margin-bottom: 0.4rem;
  text-transform: capitalize;
}

.drop-down-body a:hover,
.drop-down-body a.active {
  color: var(--bs-light);
}

.side-drawer {
  .drop-down-header {
    flex-direction: row-reverse;
    transition: border-left 0.4s, background-color 0.4s;

    .title {
      display: none;
      transition: display 0.4s;
      font-size: 1rem !important;
    }

    .chevron-icon {
      display: none;
      transition: display 0.4s;
    }

    .icon {
      display: flex;
    }

    &.active {
      transition: border-left 0.4s, background-color 0.4s;
      border-left: 12.3rem solid var(--bs-danger);

      @media only screen and (max-width: 468px) {
        border-left: 1rem solid var(--bs-danger);
      }
    }
  }

  &.open {
    .drop-down-header {
      flex-direction: row;

      .title {
        display: flex;
        transition: display 0.4s;
      }

      .chevron-icon {
        display: block;
        transition: display 0.4s;
      }

      &.active {
        transition: border-left 0.3s;
        border-left: 0.4rem solid var(--bs-danger);
      }
    }
  }
}