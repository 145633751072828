.background {
  background-color: var(--bs-grey-teal);
}
.background-whole-page {
  height: 100vh;
  background-color: var(--bs-grey-teal) !important;
  font-family: Poppins, 'normal' !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center;
  color: white;
}
.logo-container {
  height: 30vh;
  width: 800;
  margin-bottom: 40px;
}
.message-container {
  display: flex;
  margin-top: -100px;
  justify-content: center;
  padding-top: 10px;
}
.welcome-message {
  font-size: 20px;
  text-align: center;
}
