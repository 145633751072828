.common-button {
    border: 0;
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: Poppins !important;
    min-width: 7rem !important;
    border: 1px solid var(--bs-danger) !important;
}

.transparent {
    background-color: transparent !important;
    color: var(--bs-danger) !important;
    border: 1px solid var(--bs-danger);

    &:hover {
        background-color: var(--bs-danger) !important;
        color: var(--bs-light) !important;
    }
}