#video-consultation {
  .main-container {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }

  .date-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    text-align: center;
    color: var(--bs-light-slate-grey);
    text-align: center;
  }

  .day-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    text-align: center;
    color: var(--bs-light-slate-grey);
  }

  .remain-days-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.200233px;
    color: var(--bs-dark);
    margin-bottom: 0;
  }

  .remain-days-container {
    background: var(--bs-geraldine);
    border-radius: 0px 0px 12px 12px;
    align-self: flex-end;
    align-content: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .remain-days-container-offline {
    background: var(--bs-geraldine);
    border-radius: 12px 12px 0px 0px;
    align-self: flex-end;
    align-content: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .date-header-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--bs-grey-teal);
  }

  .selected-tab {
    background: #51a095;
    border-radius: 10px;
    min-width: 150px;
    width: auto;
    white-space: nowrap;
    color: var(--bs-white);
    cursor: pointer;
    border-radius: 12px;
  }

  .un-selected-tab {
    background: #dcdcdc;
    border-radius: 0px 10px 10px 0px;
    min-width: 150px;
    width: auto;
    white-space: nowrap;
    color: #545454;
    cursor: pointer;
    border-radius: 12px;
  }

  .doctor-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    text-align: center;
    padding: 10px 15px 10px 15px;
  }

  .appointment-scrollable {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .card-content {
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.02);
    word-wrap: break-word;

    .pet-name {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 0.8rem;
      color: var(--bs-dark);
    }

    .pet-type-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 0.7rem;
      color: #919191;
    }

    .pet-type {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8rem;
      color: #404040;
    }

    .appointment-type {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8rem;
      color: #404040;
    }

    .time {
      width: 7.5rem;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8rem;
      color: var(--bs-dark);
    }
  }

  .appointment-history-text {
    font-family: Poppins;
    background: var(--bs-white);
    display: flex;
    justify-content: space-between;
  }

  .appointment-history-scrollable {
    max-height: 70vh;
    overflow-y: auto;
  }

  .day-content-card {
    border-radius: 13px;
    cursor: pointer;

    .content {
      padding-top: 1vw;
      padding-bottom: 1vw;
    }

    .selected-container {
      background-color: var(--bs-black);
      border: var(--bs-white);
      border-radius: 0px 0px 0px 0px;
    }

    .selected-text {
      color: var(--bs-white);
    }
  }

  @media (min-width: 992px) {
    .col-lg-1-5 {
      width: 14.2%;
    }
  }

  .doctors-tab {
    .tab-container {
      background-color: #dcdcdc;
      border-radius: 10px;
    }
  }
}

.icon-wrapper {
  height: 33px;
  width: 33px;
  border-radius: 16.5px;
  align-items: center;
  background: var(--bs-lime-green);
  justify-content: center;
  display: flex;

  &.add-icon {
    background: var(--bs-grey-teal);
  }

  .icon {
    color: var(--bs-white);
    height: 50%;
    width: 50%;
  }
}

.doctor-list-scroll-content {
  border: 1px solid var(--bs-gray-300);
  border-radius: 10px;
}

.assign-popup {
  .doctor-row {
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: 5px;
    cursor: pointer;

    .doctor-name {
      margin-bottom: 0px;
      font-weight: 500;
      font-size: 15px;
      font-family: 'Poppins';
    }
  }
}

.today-button {
  width: 90%;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 0.8rem;
  color: black;
  background-color: white;
}

.today-button-active {
  border: 1px solid #fb8886 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 10px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #51a095 !important;
}

.mode-tab-button-off {
  background: none;
  color: black;
  border: none;
  &:hover {
    border: none;
    background: none;
    color: black;
  }
}

.mode-tab-button-on {
  background: none;
  border: none;
  color: var(--bs-geraldine);
  border-bottom: 5px solid var(--bs-geraldine);
  &:hover {
    border: none;
    background: none;
    color: var(--bs-geraldine);
    border-bottom: 5px solid var(--bs-geraldine);
  }
}

.button-make-appointment {
  background-color: var(--bs-geraldine);
  color: white;
  margin: 0.5rem;
}

.button-cancel {
  background-color: white;
  color: var(--bs-geraldine);
  margin: 0.5rem;
  border: 2px solid var(--bs-geraldine);
}

.style-text-box {
  height: 3rem;
}

.style-ispaid {
  margin-top: 1rem;
}

.style-checkbox {
  margin-top: .4rem;
}

.edit-style-pencil {
  color: var(--bs-grey-teal);
  margin-top: 10px;
}
select{
  border: 1px solid rgb(216, 216, 216);
  border-radius: 0.25rem;
}

select:focus {
  border: 1px solid white;
  outline: 3px solid lightblue;
}

.spin {
  animation: spin 60s ease infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
  0%, 40% { transform: rotate(0deg); }
  60%, 100% { transform: rotate(360deg); }
}