.masthead {
  background-color: #f8f9fa;
  padding-top: 9.5rem;
  padding-bottom: 5rem;
}

.masthead .masthead-device-mockup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.masthead .masthead-device-mockup .circle {
  position: absolute;
  fill: url(#circleGradient);
  width: 70%;
  display: none;
}

.login-button {
  background: var(--bs-geraldine);
  color: var(--bs-light);
  border-radius: 4px;
  border: 0px;
  width: 15rem;
}

.register-button {
  border: 1px solid var(--bs-geraldine);
  background: transparent;
  color: var(--bs-geraldine);
  border-radius: 4px;
  width: 15rem;
}

.masthead .masthead-device-mockup .circle .gradient-start-color {
  stop-color: var(--bs-primary);
  ;
}

.masthead .masthead-device-mockup .circle .gradient-end-color {
  stop-color: #9f1ae2;
}

.masthead .masthead-device-mockup .shape-1 {
  position: absolute;
  height: 1rem;
  width: 1rem;
  transform: rotate(-10deg);
  fill: var(--bs-primary);
  ;
  bottom: 15%;
  left: 10%;
}

.masthead .masthead-device-mockup .shape-2 {
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  fill: var(--bs-primary);
  ;
  top: 15%;
  right: 10%;
}

.masthead .masthead-device-mockup .device-wrapper {
  max-width: 10rem;
}

@media (min-width: 576px) {
  .masthead .masthead-device-mockup .circle {
    width: 100%;
    display: block;
  }

  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 12.5rem;
  }


}

@media (min-width: 768px) {
  .masthead .masthead-device-mockup .circle {
    width: 60%;
  }

  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }


}

@media (min-width: 992px) {
  .masthead .masthead-device-mockup .circle {
    width: 90%;
  }

  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }


}

@media (min-width: 1200px) {
  .masthead .masthead-device-mockup .circle {
    width: 75%;
  }

  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }


}

@media (min-width: 1400px) {
  .masthead .masthead-device-mockup .circle {
    width: 70%;
  }

  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 17rem;
  }

}

.masthead {
  margin-top: 72px;
  min-height: calc(100vh - 72px);
}

.app-badge {
  height: 4.5rem;
}

.contact-us-text {
  font-weight: 700;
  font-size: 2.5rem;
  color: #ffffff !important;
}

.main-header {
  color: var(--bs-grey-teal);
}


.navbar-row {
  width: 100%;
  align-items: center;

}

.menubtn {

  display: flex;
  float: left;

}


.list {
  display: none;
}

.list-block {
  display: block;
  width:200px;
  position: fixed;
  top: 80px;
  right: 0;
  text-align: center;
  background-color: #ffffff;

}

a {
  color: black;
}

.navbar-button {
  background: none;
  border: none;
  color: black;
  font-size: 1rem;
}

.new-page-container {
  position: absolute;
  top: 250px;
  bottom: 50px;
  width: 100%;
  padding: 5%;
  overflow-x: hidden !important;
  background-color: #FFFFFF;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}


.page-header {
  margin-top: 5rem;
}

.header-style {
  font-size: 2rem;
}

.link-style {
  color: black;

}
