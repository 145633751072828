#create-vendor-user {
  .confirm-button {
    background: var(--bs-geraldine);
    color: var(--bs-light);
    border-radius: 4px;
    border: 0px;
    width: 100%;
  }
}

th {
  font-size: 16px;
  line-height: 140%;
  font-weight: 500;
  font-family: Poppins;
}

td {
  font-size: small;
  line-height: 140%;
  color: #636363;
}

.user-multi-dropdown {
  .MuiChip-root {
    font-family: Poppins;
    font-size: 0.75rem;
    background-color: var(--bs-grey-teal);
    color: var(--bs-light);
    height: 25px;
  }

  .MuiChip-deleteIcon {
    color: unset;
    height: 20px;
    width: 20px;
  }

  .MuiChip-root {
    border-radius: 4px;
  }

  .MuiChip-label {
    font-size: 0.7rem;
  }

  .MuiSvgIcon-fontSizeSmall {
    height: 15px;
    width: 15px;
  }

  font-family: Poppins;
  font-size: medium;

  .label {
    font-family: Poppins;
    font-size: 14px;
  }

  .value {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
  }

  .input-label {
    font-family: Poppins;
    font-size: 14px;
    margin: 5px 0px 5px 0px;
  }

  .renew-label {
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: var(--bs-gray-500);
  }

  .button-text {
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    color: var(--bs-light);
  }

  .renew-button {
    width: 100%;
  }

  .subscription-card {
    box-shadow: 0px 35px 28px var(--bs-shadow);
    border-radius: 4px;
    padding: 30px;
    border-color: var(--bs-light);
  }
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-width: 1 !important;
}

.bottom-buttons-container {
  background-color: #f1f1f1;
  position: fixed;
  bottom: 0;
  width: 100%;
}

button.submit-btn {
  background-color: var(--bs-geraldine);
  border: 1px solid var(--bs-geraldine);
}

.exception-button {
  background-color: transparent;
  border: 1px solid var(--bs-geraldine);
}

#consultation-tab {
  font-family: Poppins;
  font-size: 0.8rem;

  .pet-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
  }

  .consultation-detail-tab {
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
  }

  .tab-container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.31);
    box-sizing: border-box;
    border-radius: 4px;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #000;
    background-color: var(--bs-light);
    border: 0;
    border-bottom: 3px solid #fb8886;
    border-radius: 0;
    font-weight: 600;
  }

  .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    color: #747474de;
    background-color: var(--bs-light);
    border: 0;
    border-bottom: 3px solid var(--bs-light);
    border-radius: 0;
    font-weight: 600;
  }

  #consultation-details {
    .header-container {
      background-color: #f1f1f1;
      padding-left: 5vw;
    }

    .header-text {
      color: #11263c;
      font-weight: 600;
    }

    .label {
      font-size: 0.8rem;
      color: #000;
    }

    .value {
      font-size: 0.8rem;
      color: #636363;
    }

    .pet-image-col {
      background-color: #51a095;
      border-radius: 8px;
    }

    .pet-info-left-col {
      padding-left: 6vw;
    }

    .align-center {
      display: flex;
      align-items: center;
    }
  }
}

#prescription-detail {
  .edit-icon {
    margin: 30px 0px;
    padding: 8px 15px;
    border-radius: 3px;
    border: 1px solid var(--bs-gray-400);
    width: auto;
    cursor: pointer;
    font-size: medium;
    color: var(--bs-gray-800);

    &:hover {
      border: 1px solid var(--bs-geraldine);
      background: var(--bs-geraldine);
      color: var(--bs-white);
    }
  }

  .main-container {
    max-width: 75%;
    min-width: 500px;
    margin: 30px 0px;

    .prescription-container {
      border-radius: 5px;
      border: 1px solid var(--bs-gray-400);
      margin-left: 0px !important;
      padding: 0px;

      .header-texts {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 13px;
      }
    }

    .header-container {
      padding: 30px;
      margin: 0px !important;
    }

    .bottom-container {
      background-color: var(--bs-light-cyan);
      border-top: 1px solid var(--bs-gray-400);
      padding: 30px;
      margin: 0px !important;

      .table-item {
        margin: 0px;
        padding: 0px;
        background-color: var(--bs-light-grayish-blue);
        border-radius: 5px;
        padding: 10px;

        .title {
          align-items: center;
          display: flex;
          font-family: 'Poppins';
          font-size: 13px;
        }

        .table-text {
          font-size: 12px;
          font-family: 'Poppins';

          &.description-text {
            color: var(--bs-gray-600);
            font-weight: 300;
          }
        }
      }
    }
  }
}

.add-prescription-button {
  font-size: small;
  width: auto !important;
  padding: 0.5rem 2rem;
  margin-top: 10px;
  color: var(--bs-white);

  &:hover {
    background-color: var(--bs-geraldine);
    border-color: var(--bs-geraldine);
    color: var(--bs-white);
  }
}

.status-drop-down {
  min-width: 170px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 10px !important;
  cursor: pointer;
}

.search-style {
  padding-left: 25px;
  background-color: white;
}

.ant-d-style {
  & .ant-select-selection-item {
    margin-left: 22px;
  }

  & .ant-select-selection-placeholder {
    margin-left: 15px;
  }
}

.tab-font {
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 20px;
}

.col-margin {
  margin-right: 3rem;
}

.custom-font-weight {
  font-weight: 600;
}

.prescription-header-border {
  border: 1px solid gray;
  border-radius: 5px
}

.select-wrapper{
  position: relative;
}

.single-select-dropdown-icon{
position: absolute;
top: 0;
right: 10px;
bottom: 0;
justify-content: center;
align-items: center;
display: flex;
height: 100%;
color: rgba(0, 0, 0, 0.54);
width: 9px !important;
}

.date-container{
  background-color: #E4E4E4 !important;
  padding-top: '3px' !important;
  padding-bottom: '3px' !important;
  font-weight: 600; 
  font-size: 15px !important;
  text-align: 'left' !important ;
}
