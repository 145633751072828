p {
    font-family: Poppins;
}

.tab-button-text {
    color: #747474;
    font-size: 0.8rem !important;
    font-weight: 500;
}

.tab-button {
    background-color: var(--bs-light);
    border-radius: 0px;
    border: 1px solid #C1DEEF;
    border-width: 0px 0px 0px 0px;
    height: 100%;
    margin-right: 1vw;
    margin-left: 1vw;
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-button-container {
    height: 100%;
    border: 1px solid #C1DEEF;
    border-width: 0px 0px 1px 0px;
    width: 100%;
    padding-right: 2vw;
    padding-left: 2vw;
}

.tab-button-active {
    border: 2px solid var(--bs-geraldine);
    border-width: 0px 0px 4px 0px;
}

.active-tab-text {
    color: var(--bs-dark);
}

.sub-header {
    background-color: var(--bs-gray-200);
    text-transform: capitalize;
    min-height: 4.25rem;
    display: flex;
    align-items: center;

    .title-row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .col-left {
            display: flex;
            align-items: center;
            color: var(--bs-grey-teal);
        }

        .col-right {
            text-align: right;
        }
    }
}

.tab-header {
    .tab {
        width: 100%;
        overflow-x: hidden;
        border-bottom: 4px solid var(--bs-gray-300);
        margin: 0.8rem;
        display: flex;
        justify-content: flex-start;
        text-transform: capitalize;
        white-space: nowrap;
        text-align: center;

        &.active {
            border-bottom: 4px solid var(--bs-danger);

            a {
                color: var(--bs-dark);
            }
        }

        a {
            color: var(--bs-gray-500);
            user-select: none;
        }

        p {
            font-size: 0.8rem;
            margin-bottom: 10px;
        }
    }

    .react-multi-carousel-item {
        width: max-content !important;
    }

    .react-multi-carousel-track {
        width: 100%;
    }
}
