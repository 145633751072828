.container {
  background-color: var(--bs-blue-chalk);
  height: 100vh;
  padding-top: 0%;
  text-align: center;
  font-size: 30px;
  overflow: hidden;
}

.logo-container {
  width: 55%;
  margin-bottom: -40px !important;
}

.otp-text {
  font-size: 30px;
  align-self: center;
}

.text-number {
  font-size: 18px;
  padding-top: 2%;
  font-family: Poppins;
  color: var(--bs-regentGrey);
}

.text-box {
  padding-top: 3%;
  padding-bottom: 3%;
}

.button-style {
  color: var(--bs-white);
  background-color: black;
  border-radius: 4px;
  align-items: center;
  width: 100%;
}

.resend-color {
  color: var(--bs-deep-sea-blue);
  outline: 0ch;
  border: none;
  background-color: var(--bs-white);
  text-align: center;
  width: 100%;
}

.result-messege {
  padding-left: 18%;
}

.enter-otp {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
}

.left-content {
  background-color: var(--bs-grey-teal);

  .title {
    color: var(--bs-light);
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
  }
}

.right-container {
  input {
    width: 100%;
  }

  .enter-otp {
    color: var(--bs-grey-teal);
  }

  p {
    color: var(--bs-regent-grey);
  }

  button.submit-btn {
    background-color: var(--bs-geraldine);
    border: none;
  }

  .resend-color {
    color: var(--bs-deep-sea-blue);
    font-weight: normal;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.column {
  height: 100vh;
  display: flex;
}

.logo-column {
  display: flex;
  align-items: end;
}
