
.steps {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  
  .step-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    transition: color 0.25s ease-in-out;
    text-align: center;
    text-decoration: none !important;
  }
  
  .step-item:first-child .step-progress {
    border-radius: 0.125rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .step-item:last-child .step-progress {
    border-radius: 0.125rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .step-progress {
    position: relative;
    width: 100%;
    height: 0.25rem;
  }
  
  .step-count {
    position: absolute;
    top: -0.75rem;
    left: 50%;
    width: 1.625rem;
    height: 1.625rem;
    margin-left: -0.8125rem;
    border-radius: 50%;
    font-size: 0.875rem;
    line-height: 1.625rem;
  }
  
  .step-label {
    padding-bottom: 1.5625rem;
  }
  
  .step-label > i {
    margin-top: -0.25rem;
    margin-right: 0.425rem;
    font-size: 1.2em;
    vertical-align: middle;
  }
  
  @media (max-width: 767.98px) {
    .step-label {
      font-size: 0.75rem;
    }
    .step-label > i {
      display: none;
    }
  }
  
  .steps-dark .step-item {
    color: #7d879c;
  }
  
  .steps-dark .step-count,
  .steps-dark .step-progress {
    color: #4b566b;
    background-color: #dadada;
  }
  
  .steps-dark .step-item:hover {
    color: #4b566b;
  }
  
  .steps-dark .step-item.active.current {
    color: #373f50;
    pointer-events: none;
  }
  
  .steps-dark .step-item.active .step-count,
  .steps-dark .step-item.active .step-progress {
    color: var(--bs-light);
    background-color: var(--bs-grey-teal);
  }
  
  .steps-light .step-item {
    color: rgba(255, 255, 255, 0.55);
  }
  
  .steps-light .step-count,
  .steps-light .step-progress {
    color: var(--bs-light);
    background-color: #485268;
  }
  
  .steps-light .step-item:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .steps-light .step-item.active.current {
    color: var(--bs-light);
    pointer-events: none;
  }
  
  .steps-light .step-item.active .step-count,
  .steps-light .step-item.active .step-progress {
    color: var(--bs-light);
    background-color: var(--bs-grey-teal);
  }


