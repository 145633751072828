$header-color: rgb(31, 31, 31);
$header-color-secondary: #bbb;
$header-color-hover: darken($header-color, 20%);

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
  background-color: rgba(170, 0, 0, 0.5);
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 30px;
  white-space: nowrap;
  width: 15em;
  z-index: 99999;
  pointer-events: none;
  opacity: 0.75;
  a {
    color: var(--bs-light);
    display: block;
    font-weight: 400;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
  }
}

/* ==========================================================================
Navbar styles
========================================================================== */
.jh-navbar {
  background-color: #353d47;
  padding: 0.2em 1em;
  .profile-image {
    margin: -10px 0px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .dropdown-item.active,
  .dropdown-item.active:focus,
  .dropdown-item.active:hover {
    background-color: #353d47;
  }
  .dropdown-toggle::after {
    margin-left: 0.15em;
  }
  ul.navbar-nav {
    padding: 0.5em;
    .nav-item {
      margin-left: 1.5rem;
    }
  }
  a.nav-link {
    font-weight: 400;
    > span {
      margin-left: 5px;
    }
  }
  .jh-navbar-toggler {
    color: var(--bs-gray-400);
    font-size: 1.5em;
    padding: 10px;
    &:hover {
      color: var(--bs-light);
    }
  }
}
.navbar-version {
  font-size: 10px;
  color: $header-color-secondary;
  padding: 0 0 0 10px;
}

.brand-logo {
  &:hover {
    text-decoration: none;
  }
  .brand-icon {
    height: 30px;
    width: auto;
    display: inline-block;
    img {
      height: 28px;
    }
  }
}

.brand-title {
  font-size: 24px;
  margin-left: 0.5rem;
  font-weight: 700;
  &:hover {
    text-decoration: none;
  }
}

.loading-bar {
  height: 3px;
  background-color: var(--bs-primary);
  position: absolute;
  top: 0px;
  z-index: 1031;
}

#app-header {
  a,link {
    color: var(--bs-grey-teal);
    text-decoration: none;
  }
}

.user-profile{
  font-size: 12px;
  color: #747474;
}


