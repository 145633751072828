#video-call {
  .screen-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
  }

  .main-wrapper {
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
  }

  #videos {
    position: relative;
    height: 80vh;
    width: 80vw;
    margin: auto;
    align-self: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    justify-items: center;
    align-items: center;
  }

  .video {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--bs-black);
    height: 97%;
    width: 95%;
  }

  .self-video {
    height: 20vh;
    width: 20vh;
    position: absolute;
    z-index: 20;
    right: 7vw;
    top: 7vh;
    background: var(--bs-white);
  }

  .controls {
    position: absolute;
    display: grid;
    top: 83vh;
    grid-template-columns: repeat(4, 25%);
    align-items: center;
    justify-items: center;
    z-index: 200;

    div {
      cursor: pointer;
      cursor: pointer;
      height: 45px;
      width: 45px;
      border-radius: 22.5px;
      align-items: center;
      margin: 15px;
      background: var(--bs-gray-600);
      justify-content: center;
      display: flex;

      .icon {
        color: var(--bs-white);
        height: 40%;
        width: 40%;
      }
    }
  }
}

.prescription-entry-modal {
  width: auto;
  max-width: 90vw !important;

  .modal-body {
    padding: 25px;
  }

  .modal-content {
    border-radius: 10px;
  }
}

.user-video .agora_video_player {
  object-fit: none !important;
}
