#login-form {
    font-family: Poppins;

    .content-container {
        padding-right: 0px;
        padding-right: 0px;
    }

    .login-head-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        /* identical to box height, or 31px */

        letter-spacing: -0.5px;

        color: #51A095;
    }

    .label {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 0.8rem;
        color: rgba(24, 24, 25, 0.9);
    }

    .button-label {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #FFFFFF;
    }

    .forgot-password {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #004C83;
        cursor: pointer;
    }
    .column {
        height: 100vh;
        display: flex;
    }

    .logo-column {
        display: flex;
    }
    .login-form-column {
        display: flex;
        align-items: center;
    }
}