.side-drawer {
  height: 100%;
  background: var(--bs-gray-800);
  color: var(--bs-light);
  box-shadow: 0px 1px 3px var(--bs-gray-800);
  position: fixed;
  left: 0;
  top: 0;
  width: 60%;
  max-width: 240px;
  z-index: 1500;
  transition: transform 0.3s ease-out !important;
  transform: translateX(-80%);
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3.7rem;
  padding-bottom: 3.8rem;
  .toggle-control {
    position: absolute;
    bottom: 3.8rem;
    padding: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &.open {
      justify-content: flex-start;
    }
    &:hover {
      background-color: var(--bs-secondary);
    }
    .toggle-btn {
      text-decoration: none;
      cursor: pointer;
      padding: 0 0.65rem;
      color: var(--bs-gray-400);
      &:hover {
        color: var(--bs-light);
      }
    }
  }
  @media only screen and (min-width: 992px) {
    // in case needs to keep open in large screen
    // transform: translateX(0);
  }
}
.side-drawer.open {
  transform: translateX(0);
}
::-webkit-scrollbar {
  width: 8px;
  background: auto; /* Chrome/Safari/Webkit */
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: var(--bs-shadow);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--bs-shadow);
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}



.user-section {
  font-size: 20px;
  padding: 20px 5px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.icon-background {
  border-radius: 50%;
  color: var(--bs-light);
  width: 60px;
  height: 60px;
  text-align: center;
  margin: 0px 10px;
}
.icon-image {
  padding: 3px;
  width: 60px;
  height: 60px;
}
