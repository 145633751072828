.heading-style-row {
  background-color: var(--bs-beluga);
  padding-top: 1rem;
  padding-bottom: 1rem;
  --bs-gutter-x: 0 !important;
}

.bullet-list span {
  color:black;
}
.circle-icon {
  display: flex;
  align-items: center;
  font-size: 9px;
  color: var(--bs-grey-teal);
  margin-right: 10px;
}
.icon {
  display: flex;
  align-items: center;
  width:20px;
  height: 20px;
}
.reminder-display {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reminder-title{
  font-weight: 520;
}
.reminder-item {
  display: flex;
  align-items: flex-start;
}
.selected-card {
  border-color: var(--bs-danger);
  border-width: medium;
}

.heading-style-col {
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: 400;
  float: left;
}

.style-card {
  height: 112px;
  justify-content: center;
  border-radius: 5px;
}

.float-right-span {
  float: right;
}

.heading-left-style {
  float: left;
  padding-left: 50px;
}

.heading-right-style {
  float: right;
  padding-right: 50px;
}

.text-heading-h1 {
  font-size: 1.2rem;
}
.text-heading-h2 {
  font-size: 1rem;
}
.text-heading-h3 {
  font-size: 0.8rem;
}

.subheader-style {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.heading-card {
  font: Poppins;
  font-weight: 500;
  font-size: 16px;
}

.heading-card-date {
  font: Poppins;
  font-weight: 400;
  font-size: 1rem;
  word-wrap: break-word;
}

.padding-history-card {
  padding-left: 2rem;
}

.card-align {
  display: flex;
  justify-content: space-between;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.bottom-container {
  background-color: var(--bs-light-cyan);
  border-top: 1px solid var(--bs-gray-400);
  padding: 25px;

  .table-item {
    margin: 0px;
    padding: 0px;
    background-color: var(--bs-light-grayish-blue);
    border-radius: 5px;
    padding: 10px;
    border: 0px;
    &.highlight-error {
      border: 2px solid var(--bs-geraldine) !important;
    }

    .title {
     word-break: normal;
      align-items: center;
      font-family: 'Poppins';
      font-size: 13px;
      word-wrap: break-word;
    }


    .title1{
      font-family: 'Poppins';
      font-size: 14px;
      word-wrap: break-all;
      word-break: break-all;
    }

    .table-text {
      font-size: 13px;
      font-family: 'Poppins';
    }

    .table-text-hidden {
      float:left; 
      overflow: hidden; 
      word-wrap: break-word;
    }

    .auto-width {
      min-width: auto !important;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      border-radius: 5px;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid var(--bs-light-black);
    }

    .input-box {
      background-color: transparent;
      border: 1px solid var(--bs-light-black);
      width: 85%;
      height: 100%;
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .add-item-button {
      background: var(--bs-danger);
      border-radius: 5px;
      cursor: pointer;
      width: 60px;
    }
  }
}

.prescription-header-border {
  border: 1px solid gray;
  border-radius: 5px;
}

.medical-history-type {
  font: 1rem;
  color: gray;
}

.custom-font-weight {
  font-size: 14px;
  color: #000000D9
}

.custom-value-style{
  font-size: 13px;
  color: #6C757D;
}

.date-container{
  background-color: #E4E4E4 !important;
  padding-top: '3px' !important;
  padding-bottom: '3px' !important;
  font-weight: 600; 
  font-size: 15px !important;
  text-align: 'left' !important ;
}