#consultation-detail {
  //consultation details css
}

#medical-history {
  //medical history css
}
.edit-show {
  display: flex;
  justify-content: space-between;
  font-size: medium;
}
.edit-show span {
  font-weight: 500;
  font-family: 'Poppins';
  font-style: normal;
}
.edit-pencil {
  color: var(--bs-grey-teal);
}
.trash-box {
  color: var(--bs-geraldine);
}
.pointer-style:hover {
  cursor: pointer;
}
.reminder-list {
  background-color: white;
  width: 70%;
  min-height: 90px;
   border-radius: 8px;
   margin-block: 8px;
   margin-inline: 20px;
   padding: 10px;
}
.remarks {
margin-top: 10px;
}
.close-icon {
  cursor: pointer;
  margin-bottom: 15px;
  display: flex;
  justify-content: end;
}
.icon-large {
  font-size: 20px;
}
.reminder-show {
  margin-right: 3px;
  width:80%;
}
.reminders {
  background-color: #E5E5E5;
  margin-top: 5px;
  height:100%;
}
.reminder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.add-reminder {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 100px;
  margin-top: 5px;
  padding-right: 200px;
}
.reminder-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  width: 400px;
  padding: 25px;
  background-color:white;
  border-radius: 6px;
}
.bottom-buttons-container {
  background-color: #f1f1f1;
  position: fixed;
  bottom: 0;
  width: 100%;
}

button.submit-btn {
  background-color: var(--bs-geraldine);
  border: 1px solid var(--bs-geraldine);
  font-size: 15px;
}
.exception-button {
  background-color: transparent;
  border: 1px solid var(--bs-geraldine);
}

#consultation-tab {
  font-family: Poppins;
  font-size: 0.8rem;

  .pet-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
  }

  .consultation-detail-tab {
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
  }

  .tab-container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.31);
    box-sizing: border-box;
    border-radius: 4px;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #000;
    background-color: var(--bs-light);
    border: 0;
    border-bottom: 3px solid #fb8886;
    border-radius: 0;
    font-weight: 600;
  }

  .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    color: #747474de;
    background-color: var(--bs-light);
    border: 0;
    border-bottom: 3px solid var(--bs-light);
    border-radius: 0;
    font-weight: 600;
  }

  #consultation-details {
    .header-container {
      background-color: #f1f1f1;
      padding-left: 5vw;
    }

    .header-text {
      color: #11263c;
      font-weight: 600;
    }

    .label {
      font-size: 0.8rem;
      color: #000;
    }

    .value {
      font-size: 0.8rem;
      color: #636363;
    }

    .pet-image-col {
      background-color: #51a095;
      border-radius: 8px;
    }

    .pet-info-left-col {
      padding-left: 6vw;
    }

    .pointer-style:hover {
      cursor: pointer;
    }

    .align-center {
      display: flex;
      align-items: center;
    }
  }
}
.add-test {
  margin: 30px 0px;
  padding: 8px 15px;
  border-radius: 3px;
}

#prescription-detail {
  .edit-icon {
    margin: 30px 0px;
    padding: 8px 15px;
    border-radius: 3px;
    border: 1px solid var(--bs-gray-400);
    width: auto;
    cursor: pointer;
    font-size: medium;
    color: var(--bs-gray-800);

    &:hover {
      border: 1px solid var(--bs-geraldine);
      background: var(--bs-geraldine);
      color: var(--bs-white);
    }
  }

  .main-container {
    max-width: 95% !important;
    min-width: 500px;
    margin: 30px 0px;

    .prescription-container {
      border-radius: 5px;
      border: 1px solid var(--bs-gray-400);
      margin-left: 0px !important;
      padding: 0px;

      .header-texts {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 13px;
      }
    }

    .header-container {
      padding: 7px 15px 7px 15px !important;
      margin: 0px !important;
    }

    .bottom-container {
      background-color: var(--bs-light-cyan);
      border-top: 1px solid var(--bs-gray-400);
      padding: 30px;
      margin: 0px !important;

      .table-item {
        margin: 0px;
        padding: 0px;
        background-color: var(--bs-light-grayish-blue);
        border-radius: 5px;
        padding: 10px;

        .title {
          align-items: center;
          display: flex;
          font-family: 'Poppins';
          font-size: 13px;
        }

        .table-text {
          font-size: 12px;
          font-family: 'Poppins';

          &.description-text {
            color: var(--bs-gray-600);
            font-weight: 300;
          }
        }
      }
    }
  }
}

.add-prescription-button {
  font-size: small;
  width: auto !important;
  padding: 0.5rem 2rem;
  margin-top: 10px;
  color: var(--bs-white);

  &:hover {
    background-color: var(--bs-geraldine);
    border-color: var(--bs-geraldine);
    color: var(--bs-white);
  }
}
.add-laboratory-button {
  &:hover {
    background-color: var(--bs-geraldine);
    border-color: var(--bs-geraldine);
    color: var(--bs-white);
  }
}
.add-reminder-button {
  width:150px;
  color:white;
  &:hover {
    background-color: var(--bs-geraldine);
    border-color: var(--bs-geraldine);
    color: var(--bs-white);
  }
}
.edit-button {
  margin-right: 5px;
  border: 1px solid var(--bs-grey-teal);
  border-radius: 4px;
  background-color: transparent;
}
.trash-button {
  border: 1px solid var(--bs-geraldine);
  border-radius: 4px;
  background-color: transparent;
}
.save-button{
  display: flex;
  justify-content: center;
  align-items: center;
 padding-left: 50px;
}
.status-drop-down {
  min-width: 170px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 10px !important;
  cursor: pointer;
}

.style-text-box-pet {
  width: 24vw;
}

.style-text-box-weight {
  width: 9vw;
}

.style-text-box-pet-name {
  width: 14vw;
}

.report-button {
  border: none !important;
  color: var(--bs-geraldine) !important;
}

.report-button:focus {
  border: none !important;
  color: var(--bs-geraldine) !important;
  box-shadow: none;
}

.dot-icon {
  padding-right: 5px;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.file-input:focus {
  outline: none;
}
.file-drop-area {
  border: 1px solid #ced4da;
  border-radius: 3px;
  position: relative;
  width: 450px;
  max-width: 100%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  align-items: center;
  display: flex;
}

.file-drop-area.is-active {
  background-color: #ced4da;
}

.file-fake-button {
  width: 89px;
  height: 26px;
  margin: 0px 4px 0px 0px;
  background: #f0f0f0;
  padding: 1px 6px;
  display: flex;
  align-items: center;
  border: 1px solid #918383;
}
.fake-btn-text {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.attachment-conatiner {
  overflow: hidden;
}

.invalid-label-text {
  color: var(--bs-geraldine);
}

.select-wrapper {
  position: relative;
}

.single-select-dropdown-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  color: rgba(0, 0, 0, 0.54);
  width: 9px !important;
  cursor: pointer;
}

.attachment-modal-header {
  display: flex !important;
  justify-content: space-between !important;
}

.zoom-rotate-button {
  margin-right: 5px;
  background-color: var(--bs-geraldine);
  border: none;
  padding: 1px 5px;
}

.modal-content {
  // align-self: center;
  // width: 100%;
  display: 'flex';
  justify-content: 'center';
  justify-content: center;
}

.modal-body {
  height: calc(100% - 100px);
}

.img-style {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 100%;
  min-height: 200px;
  max-height: calc(100% - 50px);
  width: 98%;
  margin: 0 auto;
  object-fit: contain;
  transform-origin: top center;
}

.select-value {
  font-weight: 400;
  line-height: 1.5;
  color: black;
  font-size: 0.8rem !important;
}
.select-value-new {
  font-weight: 400;
  line-height: 1.5;
  color:black;
  font-size: 0.8rem !important;
  width: 351px;
  height: 50px;
}
.calender-style {
  border: 1px solid rgb(204, 204, 204);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 0.8rem;
  color: black;
  background-color: white;
}

.add-prescription-inpatient {
  &:hover {
    background-color: var(--bs-geraldine);
    border-color: var(--bs-geraldine);
    color: var(--bs-white);
  }
  font-size: 0.8rem !important;
}

.prescription-item-container {
  margin-top: 20px;
  background-color: var(--bs-light-cyan);
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: 5px;
}
.medical-history-item-container {
  margin-top: 15px;
  background-color: var(--bs-light-cyan);
  padding-bottom: 20px;
  border-radius: 5px;
}
.date-container {
  background-color: #e4e4e4 !important;
  padding-top: '3px' !important;
  padding-bottom: '3px' !important;
  font-weight: 600;
  font-size: 15px !important;
  text-align: 'left' !important ;
}

.calender-error-style {
  border: 1px solid #fb8886;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 0.8rem;
  color: black;
  background-color: white;
}
.dob-datepicker {
  height: '52px';
  border-radius: '4px';
}
.ant-picker-style {
  .ant-picker-dropdown {
    height: 48px;
    font-size: 12px !important;
    z-index: 0 !important;
    border-radius: 4px;
    border: 1px solid #bcbcbc;
    color: #000;
    top: unset !important;
    left: unset !important;

    .ant-picker-panel-container {
      width: 300px;
    }

    .ant-picker-content td {
      font-size: 12px;
    }
    .ant-picker-content th {
      font-size: 14px;
      font-weight: 400;
    }

    .ant-picker-input {
      padding: 20px;
    }

    .ant-picker-clear {
      right: 5px;
    }
  }
}
.form-control::placeholder {
  color: black;
}
.MuiOutlinedInput-input::placeholder {
  font-weight: bold;
}
.save-button-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

