.prescription-entry {
  margin: 0px !important;
  border-radius: 5px;
  overflow: hidden;

  .inner-container {
    border-radius: 5px;
    border: 1px solid var(--bs-gray-400);
  }

  .header-container {
    padding: 14px;
    margin: 0px !important;
    padding-bottom: 5px;

    .header-texts {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 13px;
    }
  }

  .bottom-container {
    background-color: var(--bs-light-cyan);
    border-top: 1px solid var(--bs-gray-400);
    padding: 25px;

    .table-item {
      margin: 0px;
      padding: 0px;
      background-color: var(--bs-light-grayish-blue);
      border-radius: 5px;
      padding: 10px;
      border: 0px;

      &.highlight-error {
        border: 2px solid var(--bs-geraldine) !important;
      }

      .title {
        min-width: fit-content !important;
        align-items: center;
        display: flex;
        font-family: 'Poppins';
        font-size: 15px;
        justify-content: center;
      }
      .title-lab-report {
        min-width: fit-content !important;
        align-items: flex-start;
        display: flex;
        font-family: 'Poppins';
        font-size: 15px;
        justify-content: center;
      }

      .table-text {
        font-size: 13px;
        font-family: 'Poppins';
      }

      .auto-width {
        min-width: auto !important;
      }

      .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        border-radius: 5px;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border: 1px solid var(--bs-light-black);
      }

      .input-box {
        background-color: transparent;
        border: 1px solid var(--bs-light-black);
        width:100%;
        height: 100%;
        border-radius: 5px;
        padding-left: 10px;
        padding-right: 10px;
      }

      .add-item-button {
        background: var(--bs-danger);
        border-radius: 5px;
        cursor: pointer;
        width: 60px;
      }
    }
  }
  

  .text-area {
    font-family: 'Poppins';
    font-size: 13px;
  }
  

  .error-text {
    font-size: 13px;
    padding-left: 0px;
  }

  .save-button {
    min-width: 60px;
    padding: 8px 60px;
    color: var(--bs-white);
  }
}

.icon-paper-clip {
  background-color: var(--bs-geraldine) !important;
  border: none;
  &:hover {
    color: var(--bs-geraldine);
  }
padding-left: .5em;
}

.style-creatable-box {
  width: 220px !important;
}
.lab-report-attachment{
  background-color:#DFDFDF !important;
  border-radius: .30em;
  margin-right: .3em;
  text-align: center;
  height: 29px;
  width: 60px;
  display: flex;
  align-items: center;
}
.input-box-lab-report {
  background-color: transparent;
  border: 1px solid var(--bs-light-black);
  width: 85%;
  height: 75%;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
