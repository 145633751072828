#login-form{
    font-family: Poppins;

    .login-head-text{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        /* identical to box height, or 31px */

        letter-spacing: -0.5px;

        color:  #51A095;
    }
    .message {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: var(--bs-dawn);
    }
    .label {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        /* identical to box height, or 20px */
        letter-spacing: -0.5px;
        /* On Surface / Dark / High-emphasis */
        color: rgba(24, 24, 25, 0.9);
    }

    .button-label { 
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #FFFFFF;
    }

    .forgot-password {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #004C83;
        cursor: pointer;  
    }
}