.navbar {
  background-color: var(--bs-sea-shell);
}

.button-edit {
  border: none;
}

.header-box {
  display: flex;
  background-color: var(--bs-grey-teal);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 2%;
  margin-right: 3%;
  margin-left: 3%;
  padding-top: 8px;
  padding-bottom: 10px;
}

.text-center {
  font-size: 16px;
  text-align: center;
}

.text-format {
  font-size: 16px;
  text-align: left;
}

.text-right {
  font-size: 16px;
  display: inline;
  float: right;
  color: var(--bs-boulder);
  padding-right: 15%;
}

.profile-header-text {
  color: var(--bs-light);
}

.vendor-text-box {
  background-color: white;
  padding-left: 5%;
  border-radius: 5px;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 2%;
  box-shadow: 1px 1px 1px 1px var(--bs-gray-400);
  padding-bottom: 18px;
}

.label-style {
  padding-right: 60%;
  padding-top: 2px;
  padding-bottom: 2px;
}

.my-profile-text {
  font-size: 0.8rem;
  color: var(--bs-grey-teal) !important;
  padding-left: 3vw;
  font-weight: 500;
}